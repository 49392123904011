import React, { useState } from "react";
import { Jumbotron, Row, Col} from "react-bootstrap"; 
// import { Doughnut } from 'react-chartjs-2';
import { useAuth0 } from "@auth0/auth0-react";
import styled from 'styled-components';

const Div = styled.div`
  .profilepics{
    border-radius: 50%;
    width:25%;
    height: auto;
  }

`

const Profile = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  const day = date.getDate();
  const twoDigitDay = (day < 10 ? '0' : '') + day;
  const path = (month < 10) ? `/v2/reports/time/team?from=${year}0${month}01&to=${year}0${month}${twoDigitDay}` : `/v2/reports/time/team?from=${year}${month}01&to=${year}${month}${twoDigitDay}`;

  

  const https = require('https');
  const options = {
    protocol: "https:",
    hostname: "api.harvestapp.com",
    path: path,
    headers: {
      "User-Agent": "PPR Profile",
      "Authorization": "Bearer 2543244.pt.UP6XkfWi3ktAnPob9oBSB8Qtx7MLq2XoLrhTTJ3AGiYaEfszeKSZnb6WtUbPGMxS-s2eCUJswsW2G4GkwEMEgw",
      "Harvest-Account-ID": "1400811"
    }
  }

  const { user } = useAuth0();
  // const name = 'hmccartney@pprhr.com';
  let goal = 0;
  let currentBillings = 0;
  let teamMemberName = "";
  let hiredate = "";
  let birthday = "";
  let photo = "";
  let monthActual = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  let teamBillableData = [];
  let teamMemberBillableAmount = 0;
  let teamMemberIndex = 0;
  let teamMemberBillableHours = 0;
  let teamMemberTotalHours = 0;

  //Set Render Variables
  const [renderBillableAmount, setRenderBillableAmount] = useState(0);
  const [renderDifference, setRenderDifference] = useState(0);
  const [renderBillableHours, setRenderBillableHours] = useState(0);
  const [renderTotalHours, setRenderTotalHours] = useState(0);

  switch (user.name) {
    case "anorton@pprhr.com":
      teamMemberName = "Adam Norton";
      goal = 0;
      currentBillings = 0;
      hiredate = "11/14/2014";
      birthday = "10/12";
      photo = 'https://www.pprhr.com/assets/images/team/adam.jpg';
      monthActual[0] = 0;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
  
    
    case "canderson@pprhr.com":
      teamMemberName = "Candace Anderson";
      goal = 18000;
      currentBillings = 0;
      hiredate = "03/29/2021";
      birthday = "10/12";
      photo = 'https://www.pprhr.com/assets/images/team/candace.jpg';
      monthActual[0] = 18062.5;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
    
    case "gnorton@pprhr.com":
      teamMemberName = "Gabriela Norton";
      goal = 18000;
      currentBillings = 0;
      hiredate = "02/01/2010";
      birthday = "07/16";
      photo = 'https://www.pprhr.com/assets/images/team/gabriela.jpg';
      monthActual[0] = 23969;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
    case "hmccartney@pprhr.com":
      teamMemberName = "Haley McCartney";
      goal = 18000;
      currentBillings = 0;
      hiredate = "08/12/2024";
      birthday = "12/02";
      photo = '';
      monthActual[0] = 4309.25;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
    case "htibbetts@pprhr.com":
      teamMemberName = "Hayley Tibbetts";
      goal = 17000;
      currentBillings = 0;
      hiredate = "03/10/2021";
      birthday = "12/29";
      photo = 'https://www.pprhr.com/assets/images/team/hayley.jpg';
      monthActual[0] = 23258;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
    case "jkelly@pprhr.com":
      teamMemberName = "Jazmin Kelly";
      goal = 15000;
      currentBillings = 0;
      hiredate = "08/01/2014";
      birthday = "09/15";
      photo = 'https://www.pprhr.com/assets/images/team/jazmin.jpg';
      monthActual[0] = 14622;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
    
    case "khester@pprhr.com":
      teamMemberName = "Karen Hester";
      goal = 18000;
      currentBillings = 0;
      hiredate = "01/01/2025";
      birthday = "04/13";
      photo = 'https://www.pprhr.com/assets/images/team/placeholder-female.jpg';
      monthActual[0] = 5064;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
    
    case "kbenson@pprhr.com":
      teamMemberName = "Kacye Benson";
      goal = 15000;
      currentBillings = 0;
      hiredate = "10/09/2017";
      birthday = "05/12";
      photo = 'https://www.pprhr.com/assets/images/team/kacye.jpg';
      monthActual[0] = 18231.25;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
    
    case "llloyd@pprhr.com" || "lloyd@pprhr.com":
      teamMemberName = "Lauren Lloyd";
      goal = 0;
      currentBillings = 0;
      hiredate = "02/21/2022";
      birthday = "05/05";
      photo = 'https://www.pprhr.com/assets/images/team/lauren.jpg';
      monthActual[0] = 0;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
    case "lbenson@pprhr.com":
      teamMemberName = "Luke Benson";
      goal = 20000;
      currentBillings = 0;
      hiredate = "01/01/2022";
      birthday = "12/06";
      photo = 'https://www.pprhr.com/assets/images/team/luke.jpg';
      monthActual[0] = 30279.75;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
    case "mcontreras@pprhr.com":
      teamMemberName = "Mercedes Contreras";
      goal = 20000;
      currentBillings = 0;
      hiredate = "02/06/2023";
      birthday = "04/13";
      photo = 'https://www.pprhr.com/assets/images/team/placeholder-female.jpg';
      monthActual[0] = 21245.5;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
    case "tmiller@pprhr.com":
      teamMemberName = "Tracy Miller";
      goal = 20000;
      currentBillings = 0;
      hiredate = "04/18/2022";
      birthday = "05/21";
      photo = 'https://www.pprhr.com/assets/images/team/tracy.jpg';
      monthActual[0] = 20525.5;
      monthActual[1] = renderBillableAmount;
      monthActual[2] = 0;
      monthActual[3] = 0;
      monthActual[4] = 0;
      monthActual[5] = 0;
      monthActual[6] = 0;
      monthActual[7] = 0;
      monthActual[8] = 0;
      monthActual[9] = 0;
      monthActual[10] = 0;
      monthActual[11] = 0;
      break;
    default:
      teamMemberName = "No Name Found";
      goal = 0;
      break;
  }


  https.get(options, (res) => {
            const { statusCode } = res;

            if (statusCode !== 200) {
                console.error(`Request failed with status: ${statusCode}`);
                return;
            }

            res.setEncoding('utf8');
            let rawData = '';
            res.on('data', (chunk) => { rawData += chunk; });
            res.on('end', () => {
                try {
                    const parsedData = JSON.parse(rawData);
                    for (let i = 0; i < parsedData.results.length; i++){
                        console.log(`${parsedData.results[i].user_name} billable: $${parsedData.results[i].billable_amount}`);
                    }
                  console.log(parsedData.results);
                  teamBillableData = parsedData.results;
                  teamMemberIndex = teamBillableData.findIndex(function (teamMember) {
                    return teamMember.user_name === teamMemberName;
                  })
                  teamMemberBillableAmount = teamBillableData[teamMemberIndex].billable_amount;
                  teamMemberBillableHours = teamBillableData[teamMemberIndex].billable_hours;
                  teamMemberTotalHours = teamBillableData[teamMemberIndex].total_hours;
                  console.log(teamMemberIndex);
                  console.log(teamMemberBillableAmount);
                  setRenderBillableAmount(teamMemberBillableAmount);
                  setRenderDifference(renderBillableAmount - goal);
                  setRenderBillableHours(teamMemberBillableHours);
                  setRenderTotalHours(teamMemberTotalHours);
                } catch (e) {
                console.error(e.message);
                }
            });
            }).on('error', (e) => {
            console.error(`Got error: ${e.message}`);
            });
  

  return (
      <Div>
        <Jumbotron>
          <Row>
            <Col className="text-dark">
              <img className="profilepics mb-3" src={photo} alt={teamMemberName} />
                <p>
                  <strong>Date of Hire:</strong> {hiredate}
                </p>
                <p>
                  <strong>Birthday:</strong> {birthday}
                </p>
            </Col>
            <Col  className="mx-auto text-dark text-md-right">
                <h3 className="display-4">Hello, {teamMemberName}!</h3>
                <h5 className="pt-3">
                  Current Billable Amount: $
                  {renderBillableAmount.toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </h5>
                <p>
                  Billable Goal: ${goal.toLocaleString()} | Difference: $
                  {(renderDifference).toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </p>
            <h3 className="display-4"> 
              {/* Billable Goal Percentage */}
              {((renderBillableHours / renderTotalHours) * 100).toLocaleString("en", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}% Billable
            </h3>
            
            </Col>
        </Row>
        
        {/* <Table striped bordered size="sm" responsive="xl">
          <thead>
            <tr>
              <th>#</th>
              <th>Jan</th>
              <th>Feb</th>
              <th>Mar</th>
              <th>Apr</th>
              <th>May</th>
              <th>Jun</th>
              <th>Jul</th>
              <th>Aug</th>
              <th>Sep</th>
              <th>Oct</th>
              <th>Nov</th>
              <th>Dec</th>
              <th>Average</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Billable Amount</td>
              <td>$
                {monthActual[0].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>$
                {monthActual[1].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>$
                {monthActual[2].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>$
                {monthActual[3].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>$
                {monthActual[4].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>$
                {monthActual[5].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>$
                {monthActual[6].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>$
                {monthActual[7].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>$
                {monthActual[8].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>$
                {monthActual[9].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>$
                {monthActual[10].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>$
                {monthActual[11].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              <td>${((monthActual[0] + monthActual[1] + monthActual[2] + monthActual[3] + monthActual[4] + monthActual[5] + monthActual[6] + monthActual[7] + monthActual[8] + monthActual[9] + monthActual[10] + monthActual[11])/(month)).toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits:2,
              })}</td>
            </tr>
            <tr>
              <td>Percentage Billable</td>
              <td>78%</td>
              <td>80%</td>
              <td>90%</td>
              <td>85%</td>
              <td>87%</td>
              <td>88%</td>
            </tr>
            <tr>
              <td>Hours Worked</td>
              <td>196</td>
              <td>168</td>
              <td>152</td>
              <td>176</td>
              <td>160</td>
              <td>175</td>
            </tr>
            <tr>
              <td>Hours in Month</td>
              <td>184</td>
              <td>168</td>
              <td>168</td>
              <td>176</td>
              <td>160</td>
              <td>184</td>
            </tr>
            <tr>
              <td>% of Hour Entered</td>
              <td>110%</td>
              <td>100%</td>
              <td>100%</td>
              <td>92%</td>
              <td>100%</td>
              <td>96%</td>
            </tr>
            <tr>
              <td>Billable Hours</td>
              <td>184</td>
              <td>168</td>
              <td>168</td>
              <td>176</td>
              <td>160</td>
              <td>184</td>
            </tr>
            <tr>
              <td>Average Rate</td>
              <td>184</td>
              <td>168</td>
              <td>168</td>
              <td>176</td>
              <td>160</td>
              <td>184</td>
            </tr>
          </tbody>
        </Table> */}

          <Row className="text-dark text-center mt-4">
            <Col>
              <p>
                <strong>Jan</strong>
              </p>
              <p>
                $
                {monthActual[0].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
            <Col>
              <p>
                <strong>Feb</strong>
              </p>
              <p>
                $
                {monthActual[1].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
            <Col>
              <p>
                <strong>Mar</strong>
              </p>
              <p>
                $
                {monthActual[2].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
            <Col>
              <p>
                <strong>Apr</strong>
              </p>
              <p>
                $
                {monthActual[3].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
            <Col>
              <p>
                <strong>May</strong>
              </p>
              <p>
                $
                {monthActual[4].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
            <Col>
              <p>
                <strong>Jun</strong>
              </p>
              <p>
                $
                {monthActual[5].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
            <Col>
              <p>
                <strong>Jul</strong>
              </p>
              <p>
                $
                {monthActual[6].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
            <Col>
              <p>
                <strong>Aug</strong>
              </p>
              <p>
                $
                {monthActual[7].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
            <Col>
              <p>
                <strong>Sep</strong>
              </p>
              <p>
                $
                {monthActual[8].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
            <Col>
              <p>
                <strong>Oct</strong>
              </p>
              <p>
                $
                {monthActual[9].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
            <Col>
              <p>
                <strong>Nov</strong>
              </p>
              <p>
                $
                {monthActual[10].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
            <Col>
              <p>
                <strong>Dec</strong>
              </p>
              <p>
                $
                {monthActual[11].toLocaleString("en", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </Col>
          </Row>         
        </Jumbotron>
      </Div>
    
  );
};

export default Profile;